import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';
import Icon from '../../base_svg-icon/component';
import Checkbox from '../../ui_checkbox/component';

/**
 * A generic component that renders form inputs
 *
 * It receives input attributes as props and have them spread to the input element
 *
 * @param {Event} e
 */
class FormInput extends Component {
  constructor(props) {
    super(props);

    this.renderEmpty    = this.renderEmpty.bind(this);
    this.renderInput    = this.renderInput.bind(this);
    this.renderRadio    = this.renderRadio.bind(this);
    this.handleChange   = this.handleChange.bind(this);
    this.renderCheckbox = this.renderCheckbox.bind(this);
    this.renderTextArea = this.renderTextArea.bind(this);
  }

  /**
   * Format date
   * Expects 'dd-mm-yyyy' (cleave) and returns 'yyyy-mm-dd'
   * @param {string} date
   */
  formatDate = (date = '') => {
    const chunks = date.split('-');
    if (chunks.length === 3) {
      const [day, month, year] = chunks;
      return [year, month, day].join('-');
    }
    return '';
  };

  /**
   * Handles the onChange event on children elements, updating the formData state
   *
   * @param {Event} e
   */
  handleChange(e) {
    const { target } = e;
    const { name, type, value, checked, dataset } = target;

    /**
     * Forbid the input of spaces on the beggining
     * of the string and 2 spaces in the end of it.
     * Disallow all input fields except login to contain hashtags # and double quotes ". Because they can break the interface
     */
    let cleanValue;
    if (value.slice(-2) === '  ') {
      cleanValue = value.trimLeft().slice(0, -1);
    } else if (type === 'password'  || dataset.type === 'password') {
      cleanValue = value.trimLeft();
    } else {
      cleanValue = value.trimLeft().replace(/["#]/g, '');
    }

    // Exclude input type: checkbox, because of its boolean value
    if (type === 'checkbox') {
      cleanValue = checked;
    }

    if (this.props.htmlAttributes.type === 'date') {
      cleanValue = this.formatDate(value);
    }

    this.props.updateFormDataState(name, cleanValue);
  }


  /**
   * Function that maps input types to specific render functions
   * @param {String} inputType
   */
  renderFunctionsMap(inputType) {
    const renderFunctions = {
      select: this.renderSelect,
      checkbox: this.renderCheckbox,
      radio: this.renderRadio,
      textarea: this.renderTextArea,
      hidden: this.renderEmpty,
      default: this.renderInput,
      empty: this.renderEmpty,
      options: this.renderOptions,
      date: () => this.renderInput(true),
    };

    const renderFn = renderFunctions[inputType] || renderFunctions.default;

    return renderFn();
  }

  /**
   * Render a list preset options. Kind of like a radio input. (see gifcard form component)
   */
  renderOptions = () => {
    const {
      name,
      label,
      className,
      newRow = true,
      error,
      errorMessages = {},
      helpText = '',
      successMessage = '',
      stacked = false,
      htmlAttributes,
      clientSideErrorMsg,
      updateFormDataState,
    } = this.props;

    const { options = [], value, required } = htmlAttributes;

    const formGroupTestID = newRow && { 'data-test-id': `formGroup-${name}` };

    return (
      <div className={newRow ? 'form-group row' : ''} {...formGroupTestID}>
        {label && (
          <div className={`${stacked ? 'col-md-12' : 'col-md-4'}`}>
            <label htmlFor={name} className={`${stacked && 'top'} control-label`}>
              {label} {required && '*'}
            </label>
          </div>
        )}
        <div className={className}>
          <div className="option-buttons">
            {options.map(option => (
              <div
                key={option.value}
                value={option.value}
                className={`form-control ${value === option.value ? 'active' : ''}`}
                onClick={() => updateFormDataState(name, option.value)}
              >{option.label}</div>
            ))}
          </div>

          {this.props.description && (
            <span
              data-test-id={'selectDescription'}
              className="description"
              dangerouslySetInnerHTML={{ __html: this.props.description }}
            />
          )}
          <span
            data-test-id="selectValidationIcon"
            className="form-control-feedback"
            onClick={() => this.props.updateFormDataState(name, '')}
            aria-hidden="true"
          >
            <Icon  name="ico-check" className="success"/>
            <Icon name="ico-error" className="invalid"/>
          </span>
          <small
            data-test-id="inputHelperText"
            className="form-control-message"
            hidden={error}>{helpText}</small>
          <small
            className="form-control-error-message"
            hidden={!error}
            data-test-id="validationMessage${name}"
            dangerouslySetInnerHTML={{ __html: errorMessages[error] || clientSideErrorMsg || error }}
          />
        </div>
        <small
          data-test-id="selectSuccessMessage"
          className="form-control-success-message"
          hidden={error}>{successMessage}
        </small>
      </div>
    );
  };

  /**
   * Render function used to display an empty div when an
   * input vas visible option = false
   */
  renderEmpty() {
    return (
      <div></div>
    );
  }

  /**
   *
   * @returns Render <select/> element
   */
  renderSelect = () => {
    const {
      name,
      label,
      className,
      newRow = true,
      error,
      errorMessages = {},
      helpText = '',
      successMessage = '',
      stacked = false,
      hideValid = false,
      htmlAttributes,
      clientSideErrorMsg,
      updateFormDataState,
    } = this.props;

    const classNames = [
      'form-control',
      !!hideValid && 'hide-valid',
      !!error && 'invalid',
    ].filter(Boolean).join(' ');

    const formGroupTestID = newRow && { 'data-test-id': `formGroup-${name}` };

    const { options = [], value, required } = htmlAttributes;

    return (
      <div className={newRow ? 'form-group row' : ''} {...formGroupTestID}>
        {label && (
          <div className={`${stacked ? 'col-md-12' : 'col-md-4'}`}>
            <label htmlFor={name} className={`${stacked && 'top'} control-label`}>
              {label} {required && '*'}
            </label>
          </div>
        )}
        <div className={`select ${className}`}>
          <select
            id={name}
            value={value}
            className={classNames}
            onChange={e => updateFormDataState(name, e.target.value)}
          >
            {options.map(option => (
              <option key={option.value + option.label} value={option.value} disabled={option.disabled} hidden={option.hidden} selected={option.selected}>{option.label}</option>
            ))}
          </select>
          <div className="select-caret">
            <Icon  name="ico-arrow-down" size={87}/>
          </div>

          {this.props.description && (
            <span
              data-test-id={'selectDescription'}
              className="description"
              dangerouslySetInnerHTML={{ __html: this.props.description }}
            />
          )}
          <span
            data-test-id="selectValidationIcon"
            className="form-control-feedback"
            onClick={() => this.props.updateFormDataState(name, '')}
            aria-hidden="true"
          >
            <Icon  name="ico-check" className="success"/>
            <Icon name="ico-error" className="invalid"/>
          </span>
          <small
            data-test-id="inputHelperText"
            className="form-control-message"
            hidden={error}>{helpText}</small>
          <small
            className="form-control-error-message"
            hidden={!error}
            data-test-id="validationMessage${name}"
            dangerouslySetInnerHTML={{ __html: errorMessages[error] || clientSideErrorMsg || error }}
          />
        </div>
        <small
          data-test-id="selectSuccessMessage"
          className="form-control-success-message"
          hidden={error}>{successMessage}
        </small>
      </div>
    );
  };

  /**
   * Render function invoked when the input type is radio
   */
  renderRadio() {
    const {
      name,
      label,
      className,
      options,
      error,
      errorMessages = {},
      htmlAttributes,
      clientSideErrorMsg,
    } = this.props;

    const { required } = htmlAttributes;

    return (
      <div className="form-group row" data-test-id={`formGroup-${name}`}>
        {label && (
          <label
            htmlFor={name}
            className="col-md-4 control-label">
            {label} {required && '*'}
          </label>
        )}
        <div className={className}>
          <div className="radio">
            {options.map((option, i) => (
              <Fragment key={i}>
                <input
                  type="radio"
                  id={option.value}
                  name={name}
                  className={'form-control'}
                  checked={htmlAttributes.value === option.value}
                  onChange={this.handleChange}
                  {...htmlAttributes}
                  value={option.value}
                />
                <label
                  data-test-id={`radio-${option.value}`}
                  htmlFor={option.value}
                >
                  {option.label}
                </label>
              </Fragment>
            ))}
            <small
              className="col-xs-12 form-control-error-message"
              dangerouslySetInnerHTML={{ __html: errorMessages[error] || clientSideErrorMsg || error }}
              hidden
            />
          </div>
        </div>
      </div>
    );
  }

  /**
   * Render function that is invoked when the input type is checkbox
   */
  renderCheckbox() {
    const {
      name,
      label,
      className,
      htmlAttributes,
    } = this.props;

    const { required } = htmlAttributes;

    return (
      <div className="form-group field field-boolean" data-test-id={`formGroup-${name}`}>
        <div className={`checkbox input-container ${className}`}>
          <label data-test-id={`checkbox-${name}`}>
            <Checkbox
              type="checkbox"
              name={name}
              checked={!!htmlAttributes.value}
              onChange={this.handleChange}
              {...htmlAttributes}
            />
            <span>{label} {required && '*'}</span>
          </label>
          {this.props.description &&
            <span className="description" dangerouslySetInnerHTML={{ __html: this.props.description }}></span>
          }
        </div>
      </div>
    );
  }

  /**
   * Render function that is invoked when the input type is not checkbox or radio
   */
  renderInput(isDateInput) {
    const {
      name,
      label,
      placeholder,
      className,
      newRow = true,
      error,
      errorMessages = {},
      helpText = '',
      successMessage = '',
      onKeyUp = () => {},
      onKeyDown = () => {},
      stacked = false,
      hideValid = false,
      htmlAttributes,
      clientSideErrorMsg,
      large,
      options = {},
    } = this.props;

    const inputClassNames = [
      'form-control',
      !!hideValid && 'hide-valid',
      !!error && 'invalid',
      large && 'large',
    ].filter(Boolean).join(' ');

    let value = '';
    if (isDateInput) {
      const chunks = htmlAttributes.value ? htmlAttributes.value.split('-') : [];
      if (chunks.length === 3) {
        const [year, month, day] = chunks;
        value = [day, month, year].join('-');
      }
    }

    const formGroupTestID = newRow && { 'data-test-id': `formGroup-${name}` };

    const { required } = htmlAttributes;

    return (
      <div className={newRow ? 'form-group row' : ''} {...formGroupTestID}>
        {
          label && (
            <div className={`${stacked ? 'col-md-12' : 'col-md-4'}`}>
              <label
                htmlFor={name}
                className={`${stacked && 'top'} control-label`}>
                {label} {required && '*'}
              </label>
            </div>
          )
        }
        <div className={className}>
          {isDateInput ? (
            <Cleave
              id={name}
              name={name}
              className={inputClassNames}
              placeholder={placeholder}
              onChange={this.handleChange}
              onKeyUp={onKeyUp}
              onKeyDown={onKeyDown}
              data-test-id={`input-${name}`}
              options={{
                ...options,
                date: true,
                delimiter: '-',
                datePattern: ['d', 'm', 'Y'],
              }}
              {...htmlAttributes}
              type="text"
              value={value}
            />
          ) : (
            <input
              id={name}
              name={name}
              className={inputClassNames}
              placeholder={placeholder}
              onChange={this.handleChange}
              onKeyUp={onKeyUp}
              onKeyDown={onKeyDown}
              data-test-id={`input-${name}`}
              {...htmlAttributes}
            />
          )}

          {this.props.description && (
            <span
              data-test-id={'inputDescription'}
              className="description"
              dangerouslySetInnerHTML={{ __html: this.props.description }}
            />
          )}
          <span
            data-test-id={'inputValidationIcon'}
            className="form-control-feedback"
            onClick={() => this.props.updateFormDataState(name, '')}
            aria-hidden="true"
          >
            <Icon  name="ico-check" className="success"/>
            <Icon name="ico-error" className="invalid"/>
          </span>
          <small
            data-test-id={'inputHelperText'}
            className="form-control-message"
            hidden={error}>{helpText}</small>
          <small
            className="form-control-error-message"
            hidden={!error}
            data-test-id={`validationMessage${name}`}
            dangerouslySetInnerHTML={{ __html: errorMessages[error] || clientSideErrorMsg || error }}
          />
        </div>
        <small
          data-test-id={'inputSuccessMessage'}
          className="form-control-success-message"
          hidden={error}>{successMessage}
        </small>
      </div>
    );
  }

  /**
   * Render function that is invoked when the input type is textarea
   */
  renderTextArea() {
    const {
      name,
      label,
      className,
      error,
      newRow = true,
      stacked = false,
      errorMessages = {},
      helpText = '',
      htmlAttributes,
      clientSideErrorMsg,
    } = this.props;

    const { value, rows, placeholder, required } = htmlAttributes;
    const formGroupTestID = newRow && { 'data-test-id': `formGroup-${name}` };

    return (
      <div className={newRow ? 'form-group row' : ''} {...formGroupTestID}>
        {label &&
          <div className={`${stacked ? 'col-md-12' : 'col-md-4'}`}>
            <label htmlFor={name} className={`${stacked && 'top'} control-label`}>{label} {required && '*'}</label>
          </div>
        }
        <div className={className}>
          <textarea
            value={value}
            rows={rows}
            placeholder={placeholder}
            data-test-id={`textarea-${name}`}
          />
          {this.props.description &&
            <span className="description" dangerouslySetInnerHTML={{ __html: this.props.description }}></span>
          }
          <span className="form-control-feedback" aria-hidden="true" />
          <small className="form-control-message" hidden={error}>{helpText}</small>
          <small
            className="form-control-error-message"
            hidden={!error}
            dangerouslySetInnerHTML={{ __html: errorMessages[error] || clientSideErrorMsg || error }}
          />
        </div>
      </div>
    );
  }

  render() {
    const { visible = true, htmlAttributes = {}, readOnly = false } = this.props;
    const { type } = htmlAttributes;

    return (this.renderFunctionsMap((visible && !readOnly) ? type : 'empty'));
  }
}

FormInput.propTypes = {
  /**
   * The string used as the name of the input element
   */
  name: PropTypes.string.isRequired,
  /**
   * The input type
   * @default text
   */
  type: PropTypes.string,
  /**
   * The classname to be applied to the inputs outer div
   */
  className: PropTypes.string,
  /**
   * The string used as the label attribute value
   */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /**
   * The string used as the placeholder attribute value
   */
  placeholder: PropTypes.string,
  /**
   * The string used as the description
   */
  description: PropTypes.string,
  /**
   * This entry determines if the browser should show
   * autocomplete hints when the user fills the form
   */
  autoComplete: PropTypes.string,
  /**
   * The message displayed when the field validation fails.
   */
  clientSideErrorMsg: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /**
   * Accepts a regex that is used on form validity check.
   */
  pattern: PropTypes.string,
  /**
   * Accepts a regex that is used on form validity check.
   */
  newRow: PropTypes.bool,
  /**
   * If true, renders the input in a new row.
   * @default true
   */
  required: PropTypes.bool,
  /**
   * If true, displays the input.
   * @default true
   */
  visible: PropTypes.bool,
  /**
   * This entry determines if the browser should show
   * autocomplete hints when the user fills the form
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  stacked: PropTypes.bool,
  updateFormDataState: PropTypes.func,
  options: PropTypes.any,
  error: PropTypes.any,
  errorMessages: PropTypes.object,
  displayValues: PropTypes.any,
  helpText: PropTypes.any,
  successMessage: PropTypes.string,
  /**
   * Hide valid styling (for password fields)
   */
  hideValid: PropTypes.bool,
  input: PropTypes.any,
  disabled: PropTypes.bool,
  htmlAttributes: PropTypes.object,
  readOnly: PropTypes.bool,
  large: PropTypes.bool,
  onLoad: PropTypes.func,
};

export default FormInput;
