import React from 'react';
import Container from '../../ui_container/component';
import './styles.scss';

import visa from './icons/visa.svg';
import mastercard from './icons/mastercard.svg';
import becommerce from './icons/becommerce.svg';
import ideal from './icons/ideal.svg';
import paypal from './icons/paypal.svg';
import thuiswinkel from './icons/thuiswinkel.svg';

import boekenbonImage from './icons/boekenbon.png';
import vvvImage from './icons/vvv.png';
import ecommerceEuropeImage from './icons/ecommerce-europe.png';
import boekcadeau from './icons/boekcadeau.png';
import leescadeau from './icons/leescadeau.png';
import billink from './icons/billink.png';

import { FOOTER } from '@config';
const { icons = [] } = FOOTER;

const IconBar = () => {
  const iconMap = {
    becommerce,
    ideal,
    mastercard,
    paypal,
    thuiswinkel,
    visa,
    boekenbon: boekenbonImage,
    vvv: vvvImage,
    ecommerceEurope: ecommerceEuropeImage,
    boekcadeau,
    leescadeau,
    billink,
  };

  const getIconComponent = (key) => {
    const icon = iconMap[key];
    // For .png files
    const isString = typeof icon === 'string';
    if (isString) {
      return <img src={icon} height="42"/>;
    }
    const Component = icon;
    return <Component />;
  };

  if (!icons.length) {
    return null;
  }

  return (
    <div className="footer-icon-bar">
      <Container>
        <div className="footer-icon-list">
          {icons.map((icon, index) => {
            if (!iconMap[icon.key]) {
              return;
            }
            return (
              <a href={icon.url} target={icon.target || ''} key={index}>
                {getIconComponent(icon.key)}
              </a>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default IconBar;
